<template>
    <div>
        <CompModal ref="comp_model" class="residentdatabase-data-control" :title="title" width="500px">
            <div class="module-item">
                <div class="title">核心数据</div>
                <div class="row-box">
                    <div class="item-box" @click="onJumpData('report')">
                        <div class="value">{{ reportUserCount || 0 }} 人</div>
                        <div class="name">报表数据</div>
                        <div class="hint">全部满足报表条件的居民</div>
                    </div>
                    <div class="item-box" @click="onJumpData('collect')">
                        <div class="value">{{ appUserCount || 0 }} 人</div>
                        <div class="name">采集数据</div>
                        <div class="hint">仅包含定制功能采集的居民</div>
                    </div>
                </div>
            </div>

            <div class="module-item">
                <div class="title">重要功能</div>
                <div class="fun-btns">
                    <div class="btn-box" @click="onDisplayQrCode">分享二维码</div>
                    <div class="btn-box" @click="onDisplayCreate">新增</div>
                    <div class="btn-box" @click="onDisplayImport">导入</div>
                    <div class="btn-box" @click="onExport">导出</div>
                    <div class="btn-box" @click="onDisplaySendMessage('push')">发消息</div>
                    <!-- <div class="btn-box" @click="onDisplaySendMessage('sms')">发短信</div> -->
                    <div class="btn-box" @click="onDisplayVisibleRange">设置可见范围</div>
                    <div class="btn-box" @click="onDisplayQueryCriteria">设置查询条件</div>
                    <div class="btn-box" @click="onJumpEdit">编辑</div>
                    <!-- <div class="btn-box" @click="onTriggerUpdataData">更新数据</div> -->
                </div>
            </div>

            <div class="module-item">
                <div class="title">名称定义</div>
                <div class="inputs-box">
                    <div class="item-box">
                        <p class="name">常用功能名称</p>
                        <input class="input" v-model="detail.appName" placeholder="请输入常用功能名称" @blur="onBlurNameInput($event, '1')" />
                    </div>
                    <div class="item-box">
                        <p class="name">人员类型名称</p>
                        <input class="input" v-model="detail.reportName" placeholder="请输入人员类型名称" @blur="onBlurNameInput($event, '2')" />
                    </div>
                </div>
            </div>

            <div class="module-item">
                <div class="title">排序</div>
                <input class="sort-input" v-model="detail.sort" type="number" placeholder="请输入序号" @blur="onBlurSortInput" />
            </div>

            <div class="module-item">
                <div class="title">停用功能</div>
                <div class="suspend-btn" @click="onSetDisabled">停用</div>
            </div>
        </CompModal>

        <!-- 导入 -->
        <ImportsUpload ref="imports_upload" :appId="appId" :code="orgCode" @on-success="getDetailData"></ImportsUpload>
        <!-- 表单 -->
        <DataForm ref="data_form" :title="title" @on-change="getDetailData" :code="orgCode"></DataForm>
        <!-- 二维码 -->
        <CompQrCode ref="comp_qrcode"></CompQrCode>
        <!-- 发消息 -->
        <SendMessage ref="send_message" :communityCode="orgCode"></SendMessage>
        <!-- 查询条件 -->
        <QueryCriteria ref="query_criteria"></QueryCriteria>
        <!-- 设置可见范围 -->
        <VisibleRange ref="visible_range"></VisibleRange>
    </div>
</template>

<script>
import proxy from "@/api/proxy"

import CompModal from "../components/CompModal.vue"
import CompQrCode from "../components/CompQrCode.vue"

import DataForm from "./form"
import ImportsUpload from "../imports/upload"
import SendMessage from "../children/SendMessage.vue"
import QueryCriteria from "../children/QueryCriteria"
import VisibleRange from "../children/VisibleRange.vue"
import Route from "../../communitymobilization/entity/Route"

export default {
    components: {
        CompModal,
        CompQrCode,

        DataForm,
        ImportsUpload,
        SendMessage,
        QueryCriteria,
        VisibleRange,
    },

    data() {
        return {
            appId: null,
            title: null,
            // 报表数据人数
            reportUserCount: 0,
            // 采集数据人数
            appUserCount: 0,
            // 详情数据
            detail: {},
        }
    },

    props: {
        orgCode: String,
        orgName: String,
    },

    mounted() {},

    methods: {
        async display(v) {
            this.detail = v
            this.appId = v.appId
            this.title = v.appName
            // 获取详情
            this.getDetailData()
            // 获取统计
            this.getCountData()
            // 显示弹窗
            this.$refs.comp_model.display()
        },

        /**
         * 获取详情
         */
        getDetailData() {
            this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: this.appId,
            }).then(res => {
                if (res.code == "200") {
                    const data = res.data
                    const detail = this.detail

                    this.$set(detail, "appName", data.appName)
                    this.$set(detail, "reportName", data.reportName)
                    this.$set(detail, "sort", data.seq)
                }
            })
        },

        /**
         * 获取统计数据
         */
        getCountData() {
            return this.$get("/syaa/api/syuser/pc/userBaseIndex/countUserByAppId", {
                communityCode: this.orgCode,
                appId: this.appId,
            }).then(res => {
                if (res.code == 200) {
                    let { appUserCount, reportUserCount } = res.data
                    this.appUserCount = appUserCount
                    this.reportUserCount = reportUserCount
                }
            })
        },

        onDisplayQrCode() {
            this.$refs.comp_qrcode.display(`${proxy["/custom"].target}/#/apps/suiyue/pages/residentdatabase/form/form?id=${this.appId}&orgCode=${this.orgCode}`, this.detail.appName, this.orgName)
        },

        /**
         * 显示可见范围设置页面
         */
        onDisplayVisibleRange() {
            this.$refs.visible_range.display(this.appId)
        },

        onDisplayCreate() {
            this.$refs.data_form.display(this.appId)
        },

        onDisplayImport() {
            this.$refs.imports_upload.display()
        },

        onDisplayQueryCriteria() {
            this.$refs.query_criteria.display(this.appId)
        },

        /** 显示发消息页面 */
        onDisplaySendMessage() {
            this.$refs.send_message.display(this.appId)
        },

        /** 触发更新数据 */
        onTriggerUpdataData() {},

        /**
         * 导出功能
         */
        onExport() {
            this.$get(`/syaa/api/syuser/pc/userBaseIndex/exportReportExcel?communityCode=${this.orgCode}&appId=${this.appId}`).then(res => {
                if (res.code != 200) {
                    return this.$Message.error(res.desc)
                }

                // 创建一个 a 标签用于下载
                let e = document.createElement("a")

                e.href = res.data
                e.download = "数据导出_" + new Date().getTime()

                document.body.appendChild(e)
                e.click()
                document.body.removeChild(e)
            })
        },

        /** 跳转到数据页面 */
        onJumpData(s) {
            this.onJump(`/residentdatabasedatatable?id=${this.appId}&title=${this.title}&d=${this.status == "停用"}&orgCode=${this.orgCode}&s=${s}&j=index`)
        },

        /** 跳转到编辑页面 */
        onJumpEdit() {
            this.onJump(`/residentdatabasefixedbusiness?id=${this.appId}&orgCode=${this.orgCode}`)
        },

        onJump(path) {
            // 关闭弹窗
            this.$refs.comp_model.closeAll()
            // 跳转
            Route.jump(path)
        },

        /**
         * 名称输入框失焦事件，保存名称
         */
        onBlurNameInput(evt, type) {
            this.$post("/gateway/api/fastDev/AppForm/setAppName", {
                appId: this.appId,
                nameType: type,
                appName: evt.target.value,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("设置成功")
                } else {
                    this.$Message.error(res.desc)
                }
            })
        },

        /**
         * 排序输入框失焦事件，保存排序
         */
        onBlurSortInput(evt) {
            this.$post("/gateway/api/fastDev/AppForm/updateAppSeq/" + this.appId, {
                seq: evt.target.value,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("设置成功")
                } else {
                    this.$Message.error(res.desc)
                }
            })
        },

        /**
         * 停止应用
         */
        onSetDisabled() {
            this.$Modal.confirm({
                title: "提示",
                content: "您正在操作停止应用，是否继续？",
                onOk: () => {
                    this.$post("/gateway/api/fastDev/AppForm/stopApp/" + this.appId).then(res => {
                        if (res.code == 200) {
                            this.$Message.success("已停止应用")
                            // 显示弹窗
                            this.$refs.comp_model.close()
                            // 刷新数据
                            this.$emit("on-refresh")
                        } else {
                            this.$Message.error(res.desc)
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less">
.residentdatabase-data-control {
    .module-item {
        margin: 10px 20px;
        box-sizing: border-box;

        > .title {
            font-size: 16px;
            font-weight: bold;
            line-height: 40px;
            color: #287aad;
        }

        .row-box {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .item-box {
                cursor: pointer;
                width: calc(50% - 5px);
                border: 1px solid #f3f3f3;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;

                .value {
                    padding: 10px 0;
                    font-size: 22px;
                    color: #29cb97;
                    line-height: 1;
                    font-weight: bold;
                }

                .name {
                    font-size: 14px;
                    color: #333;
                }

                .hint {
                    font-size: 12px;
                    color: #666;
                }
            }
        }

        .fun-btns {
            display: flex;
            flex-wrap: wrap;

            .btn-box {
                cursor: pointer;
                margin-right: 7px;
                margin-bottom: 7px;
                width: calc(100% / 4 - 7px + 7px / 4);
                border: 1px solid #f3f3f3;
                height: 35px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background: #f6f6f6;

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }

        .inputs-box {
            .item-box {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .name {
                    margin-right: 10px;
                    flex-shrink: 0;
                    color: #666;
                }

                .input {
                    padding: 5px 10px;
                    border: 1px solid #e3e3e3;
                    flex-grow: 1;
                    font-size: 14px;
                    border-radius: 6px;
                }
            }
        }

        .sort-input {
            width: 200px;
            padding: 5px 10px;
            border: 1px solid #e3e3e3;
            flex-grow: 1;
            font-size: 14px;
            border-radius: 6px;
        }

        .suspend-btn {
            cursor: pointer;
            width: 100px;
            border: 1px solid #f3f3f3;
            height: 35px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            background: #f6f6f6;
        }
    }
}
</style>
